import work1 from "../Assets/vegza/2000x2000 (1).mp4"
import work2 from "../Assets/termokos/termokos-1.jpg"

import vegza1 from "../Assets/vegza/vegza-44.jpg"
import vegza2 from "../Assets/vegza/vegza-45.jpg"
import vegza3 from "../Assets/vegza/vegza-48.jpg";
import vegza4 from "../Assets/vegza/vegza.mp4"
import vegza5 from "../Assets/vegza/vegza-46.jpg"
import vegza6 from "../Assets/vegza/vegza-49.jpg"
import vegza7 from "../Assets/vegza/vegza-50.jpg"
import vegza8 from "../Assets/vegza/vegza-51.jpg"
import vegza9 from "../Assets/vegza/vegza-52.jpg"
import vegza10 from "../Assets/vegza/vegza-53.jpg"
import vegza11 from "../Assets/vegza/vegza-54.jpg"

import termokosBanner from "../Assets/termokos/termokosVideo.mp4"
import termokos2 from "../Assets/termokos/termokos-3.jpg"
import termokos3 from "../Assets/termokos/Logo_Animation.mp4"
import termokos4 from "../Assets/termokos/termokos-4.jpg"
import termokos5 from "../Assets/termokos/termokos-5.jpg"
import termokos6 from "../Assets/termokos/termokos-6.jpg"
import termokos7 from "../Assets/termokos/termokos-7.jpg"
import termokos8 from "../Assets/termokos/termokos-8.jpg"
import termokos9 from "../Assets/termokos/termokos-9.jpg"
import termokos10 from "../Assets/termokos/termokos-10.jpg"
import termokos11 from "../Assets/termokos/termokos-11.jpg"
import termokos12 from "../Assets/termokos/termokos-12.jpg"
import termokos13 from "../Assets/termokos/termokos-13.jpg"
import termokos14 from "../Assets/termokos/termokos-14.jpg"
import termokos15 from "../Assets/termokos/termokos-15.jpg"
import termokos16 from "../Assets/termokos/termokos-16.jpg"
import termokos17 from "../Assets/termokos/termokos-17.jpg"
import termokos18 from "../Assets/termokos/termokos-18.jpg"

import mokne1 from "../Assets/mokne/mokne.png"
import mokne2 from "../Assets/mokne/mokneVideo1.mp4"
import mokne3 from "../Assets/mokne/mokne-3.jpg"
import mokne4 from "../Assets/mokne/mokne-4.jpg"
import mokne5 from "../Assets/mokne/mokne-5.jpg"
import mokne6 from "../Assets/mokne/mokne-6.jpg"
import mokne7 from "../Assets/mokne/mokne-7.jpg"
import mokne8 from "../Assets/mokne/mokne-8.jpg"
import mokneVideo from "../Assets/mokne/mokneVideo2.mp4"
import mokne9 from "../Assets/mokne/mokne-10.jpg"
import mokne10 from "../Assets/mokne/mokne-11.jpg"
import mokne11 from "../Assets/mokne/mokne-12.jpg"
import mokne12 from "../Assets/mokne/mokne-13.jpg"
import mokne13 from "../Assets/mokne/mokne-14.jpg"
import mokne14 from "../Assets/mokne/mokne-15.jpg"

import mainBoyut from "../Assets/boyut/boyut-1.jpg"
import boyut2 from "../Assets/boyut/boyut11.mp4"
import boyut3 from "../Assets/boyut/boyutAnimation.mp4"
import boyut4 from "../Assets/boyut/boyut10.mp4"
import boyut5 from "../Assets/boyut/boyut5.png"
import boyut6 from "../Assets/boyut/boyut6.png"
import boyut7 from "../Assets/boyut/boyut7.png"
import boyut8 from "../Assets/boyut/boyut4.png"
import boyut9 from "../Assets/boyut/boyut8.png"
import boyut10 from "../Assets/boyut/boyut9.png"
import boyut11 from "../Assets/boyut/boyut3.png"
// import boyut12 from "../Assets/boyut/boyut11.mp4"
import boyut13 from "../Assets/boyut/boyut12.png"
import boyut14 from "../Assets/boyut/boyut13.png"
import boyut15 from "../Assets/boyut/boyut14.png"
import boyut16 from "../Assets/boyut/boyut15.mp4"
import boyut17 from "../Assets/boyut/boyut16.png"
import boyut18 from "../Assets/boyut/boyut-88.jpg"

import kidsday1 from "../Assets/kidsday/Post_01.mp4"
import kidsday2 from "../Assets/kidsday/Post_02.mp4"
import kidsday3 from "../Assets/kidsday/Post_03.mp4"
import kidsday4 from "../Assets/kidsday/Post_04.mp4"
import kidsday5 from "../Assets/kidsday/Post_05.mp4"
import kidsday6 from "../Assets/kidsday/Post_06.mp4"
import kidsday7 from "../Assets/kidsday/Post_07.mp4"
import kidsday8 from "../Assets/kidsday/Post_08.mp4"
import kidsday9 from "../Assets/kidsday/Post_09.mp4"
import kidsday10 from "../Assets/kidsday/Post_10.mp4"
import kidsday11 from "../Assets/kidsday/Post_11.mp4"
import kidsday12 from "../Assets/kidsday/Post_12.mp4"
import kidsday13 from "../Assets/kidsday/kidsday13.png"
import kidsday14 from "../Assets/kidsday/kidsday14.png"
import kidsday15 from "../Assets/kidsday/kidsday15.jpg"
import kidsday16 from "../Assets/kidsday/kidsday16.jpg"
import kidsday17 from "../Assets/kidsday/kidsday17.jpg"
import kidsday18 from "../Assets/kidsday/kidsday18.jpg"

import gjelber from "../Assets/gjelber/gjelber-1.mp4"
import gjelber2 from "../Assets/gjelber/gjelber-2.mp4"
import gjelber3 from "../Assets/gjelber/gjelber-3.mp4"
import gjelber4 from "../Assets/gjelber/gjelber-06 (1).png"
import gjelber5 from "../Assets/gjelber/gjelber-4.mp4"
import gjelber6 from "../Assets/gjelber/gjelber-5.mp4"
import gjelber7 from "../Assets/gjelber/gjelber-6.mp4"
import gjelber8 from "../Assets/gjelber/gjelber-7.mp4"
import gjelber9 from "../Assets/gjelber/gjelber-8.mp4"
import gjelber10 from "../Assets/gjelber/gjelber-9.mp4"
import gjelber11 from "../Assets/gjelber/gjelber-10.mp4"
import gjelber12 from "../Assets/gjelber/gjelber-11.jpg"
import gjelber13 from "../Assets/gjelber/gjelber-11 (1).png"
import gjelber14 from "../Assets/gjelber/gjelber-12.jpg"
import gjelber15 from "../Assets/gjelber/gjelber-13.jpg"
import gjelber16 from "../Assets/gjelber/gjelber-15.jpg"
import gjelber17 from "../Assets/gjelber/gjelber-16.jpg"
import gjelber18 from "../Assets/gjelber/gjelber-17.jpg"
import gjelber19 from "../Assets/gjelber/gjelber-18.jpg"
import gjelber20 from "../Assets/gjelber/gjelber-19.jpg"
import gjelber21 from "../Assets/gjelber/gjelber-20.jpg"
import gjelber22 from "../Assets/gjelber/gjelber-21.jpg"
import gjelber23 from "../Assets/gjelber/gjelber-22.jpg"
import gjelber24 from "../Assets/gjelber/gjelber-14 (1).png"




import emona from "../Assets/emona/EMONA.jpg"
import emona2 from "../Assets/emona/emona-video (1).mp4"
import emona3 from "../Assets/emona/emona-tea (1).jpg"
import emona4 from "../Assets/emona/2-tea (1).jpg"
import emona5 from "../Assets/emona/3-tea (1).jpg"
import emona6 from "../Assets/emona/4-tea (1).jpg"
import emona7 from "../Assets/emona/6-emona (1) (1).jpg"
import emona8 from "../Assets/emona/6-emona (2) (1).jpg"
import emona9 from "../Assets/emona/emona-2-animation (1).mp4"
import emona10 from "../Assets/emona/emona-3-animation (1).mp4"

import epoque1 from "../Assets/epoque/epoque-1.mp4"
import epoque2 from "../Assets/epoque/epoque-2.mp4"
import epoque3 from "../Assets/epoque/epoque-3.mp4"
import epoque4 from "../Assets/epoque/epoque-4.mp4"
import epoque5 from "../Assets/epoque/epoque-5.mp4"
import epoque6 from "../Assets/epoque/epoque-6.mp4"
import epoque7 from "../Assets/epoque/epoque-7.mp4"
import epoque8 from "../Assets/epoque/epoque-8.jpg"
import epoque9 from "../Assets/epoque/epoque-9.mp4"
import epoque10 from "../Assets/epoque/epoque-10.mp4"
import epoque11 from "../Assets/epoque/epoque-11.mp4"
import epoque12 from "../Assets/epoque/epoque-12.jpg"
import epoque13 from "../Assets/epoque/epoque-13.mp4"

import astravel1 from "../Assets/astravel/astravel-1.png"
import astravel2 from "../Assets/astravel/15VJETOR_3 (1).mp4"
import astravel3 from "../Assets/astravel/astravel-1-animation.mp4"
import astravel4 from "../Assets/astravel/astravel-2-animation.mp4"
import astravel5 from "../Assets/astravel/astravel-3-animation.mp4"
import astravel6 from "../Assets/astravel/astravel-4-animation.mp4"
import astravel7 from "../Assets/astravel/astravel-10.png"
import astravel8 from "../Assets/astravel/astravel-11.png"
import astravel9 from "../Assets/astravel/astravel-16.png"
import astravel10 from "../Assets/astravel/asas.jpg"
import astravel11 from "../Assets/astravel/astravel-17.png"
import astravel12 from "../Assets/astravel/astravel-15.png"
import astravel13 from "../Assets/astravel/asasas.jpg"
import astravel14 from "../Assets/astravel/astravel-7.png"
import astravel15 from "../Assets/astravel/astravel-9.png"
import astravel16 from "../Assets/astravel/2 (1).mp4"
import astravel17 from "../Assets/astravel/3 (1).mp4"
import astravel18 from "../Assets/astravel/4 (1).mp4"
import astravel19 from "../Assets/astravel/astravel-12.png"
import astravel20 from "../Assets/astravel/astravel-13.png"
import astravel21 from "../Assets/astravel/astravel-14.png"

import dokutech1 from "../Assets/dokutech1/Untitle1312d-2-01 (1).png"
import dokutech2 from "../Assets/dokutech1/dokutech-1.mp4"
import dokutech3 from "../Assets/dokutech1/dokutech-2.mp4"
import dokutech4 from "../Assets/dokutech1/dokutech-3.mp4"
import dokutech5 from "../Assets/dokutech1/dokutech-4.mp4"
import dokutech6 from "../Assets/dokutech1/dokutech-5.mp4"
import dokutech7 from "../Assets/dokutech1/dokutech-6.mp4"
import dokutech8 from "../Assets/dokutech1/dokutech-7.mp4"
import dokutech9 from "../Assets/dokutech1/dokutech-8.mp4"
import dokutech10 from "../Assets/dokutech1/dokutech-9.mp4"
import dokutech11 from "../Assets/dokutech1/Untitle1312d-2-02 (1).png"
import dokutech12 from "../Assets/dokutech1/Untitle1312d-2-07 (1).png"
import dokutech13 from "../Assets/dokutech1/Untitle1312d-2-08 (1).png"
import dokutech14 from "../Assets/dokutech1/DokuTechFinal.mp4"
import dokutech15 from "../Assets/dokutech1/Untitle1312d-2-09 (1).png"
import dokutech16 from "../Assets/dokutech1/Untitle1312d-2-05 (1).png"
import dokutech17 from "../Assets/dokutech1/Untitle1312d-2-06 (1).png"
import dokutech18 from "../Assets/dokutech1/Untitle1312d-2-10 (1).png"

import kok1 from "../Assets/komitetiOlimpik/kok-final.mp4"

import drops1 from "../Assets/drops/drops-1.mp4"
import drops2 from "../Assets/drops/drops-2.mp4"
import drops3 from "../Assets/drops/drops-3.mp4"
import drops4 from "../Assets/drops/drops-4.mp4"
import drops5 from "../Assets/drops/drops-5.mp4"
import drops6 from "../Assets/drops/drops-6.png"
import drops7 from "../Assets/drops/drops-7.png"
import drops8 from "../Assets/drops/drops-8.png"
import drops9 from "../Assets/drops/drops-9.mp4"
import drops10 from "../Assets/drops/drops-10.mp4"
import drops11 from "../Assets/drops/drops-11.png"
import drops12 from "../Assets/drops/drops-12.jpg"
import drops13 from "../Assets/drops/drops-13.png"
import drops14 from "../Assets/drops/drops-14.mp4"
import drops15 from "../Assets/drops/drops-15.mp4"


import herzpraxis1 from "../Assets/herzpraxis/herzpraxis1.jpg"
import herxpraxis2 from "../Assets/herzpraxis/herzpraxis2.jpg"
import herzpraxis3 from "../Assets/herzpraxis/herzpraxis3.jpg"
import herzpraxis4 from "../Assets/herzpraxis/herzpraxis4.jpg"
import herzpraxis5 from "../Assets/herzpraxis/herzpraxis5.jpg"
import herzpraxis6 from "../Assets/herzpraxis/herzpraxis6.jpg"
import herzpraxis7 from "../Assets/herzpraxis/herzpraxis7.jpg"
import herzpraxis8 from "../Assets/herzpraxis/herzpraxis8.mp4"
import herzpraxis9 from "../Assets/herzpraxis/herzpraxis9.jpg"
import herzpraxis10 from "../Assets/herzpraxis/herzpraxis10.jpg"
import herzpraxis11 from "../Assets/herzpraxis/herzpraxis11.jpg"
import herzpraxis12 from "../Assets/herzpraxis/herzpraxis12.jpg"
import herzpraxis13 from "../Assets/herzpraxis/herzpraxis13.jpg"
import herzpraxis14 from "../Assets/herzpraxis/herzpraxis14.jpg"
import herzpraxis15 from "../Assets/herzpraxis/herzpraxis15.jpg"
import herzpraxis16 from "../Assets/herzpraxis/herzpraxis16.jpg"
import herzpraxis17 from "../Assets/herzpraxis/herzpraxis17.jpg"
import herzpraxis18 from "../Assets/herzpraxis/herzpraxis18.jpg"
import herzpraxis19 from "../Assets/herzpraxis/herzpraxis19.jpg"

import floil1 from "../Assets/floilKampanja/floil-1.png"
import floil2 from "../Assets/floilKampanja/floil-2.png"
import floil3 from "../Assets/floilKampanja/floil-3.png"
import floil4 from "../Assets/floilKampanja/floil-4.mp4"
import floil5 from "../Assets/floilKampanja/floil-5.mp4"
import floil6 from "../Assets/floilKampanja/floil-6.mp4"
import floil7 from "../Assets/floilKampanja/floil-7.png"
import floil8 from "../Assets/floilKampanja/floil-8.png"
import floil9 from "../Assets/floilKampanja/floil-9.png"
import floil10 from "../Assets/floilKampanja/floil-10.png"
import floil11 from "../Assets/floilKampanja/floil-11.png"
import floil12 from "../Assets/floilKampanja/floil-12.png"
import floil13 from "../Assets/floilKampanja/floil-13.jpg"
import floil14 from "../Assets/floilKampanja/floil-14.jpg"
import floil15 from "../Assets/floilKampanja/floil-15.jpg"

import lemonita1 from "../Assets/lemonita/lemonita-1.mp4"
import lemonita2 from "../Assets/lemonita/LEMONITA-2.mp4"
import lemonita3 from "../Assets/lemonita/lemonita-3.mp4"
import lemonita4 from "../Assets/lemonita/lemonita-4.mp4"
import lemonita5 from "../Assets/lemonita/lemonita-5.mp4"
import lemonita6 from "../Assets/lemonita/lemonita-6.mp4"
import lemonita7 from "../Assets/lemonita/lemonita-7.mp4"
import lemonita8 from "../Assets/lemonita/lemonita-8.mp4"
import lemonita9 from "../Assets/lemonita/lemonita-9.mp4"
import lemonita10 from "../Assets/lemonita/lemonita-10.jpg"
import lemonita11 from "../Assets/lemonita/lemonita-11.jpg"
import lemonita12 from "../Assets/lemonita/lemonita-12.mp4"
import lemonita13 from "../Assets/lemonita/lemonita-13.jpg"
import lemonita14 from "../Assets/lemonita/lemonita-14.mp4"
import lemonita15 from "../Assets/lemonita/lemonita-15.jpg"


const ourWorks = [
  {
    id: 22,
    workImage: lemonita1,
    firstSinglePhoto: lemonita1,
    secondSinglePhoto: lemonita2,
    thirdSinglePhoto: lemonita3,
    fourthSinglePhoto: lemonita4,
    fifthSinglePhoto: lemonita6,
    sixthSinglePhoto: lemonita7,
    seventhSinglePhoto: lemonita5,
    eightSinglePhoto: lemonita8,
    ninthSinglePhoto: lemonita9,
    tenthSinglePhoto: lemonita10,
    eleventhSinglePhoto: lemonita11,
    twelfthSinglePhoto: lemonita12,
    thirteenSinglePhoto: lemonita13,
    fourteenthSinglePhoto: lemonita14,
    fifteenthSinglePhoto: lemonita15,
    workName: "LEMONITA",
    category: "Design Brand Strategy / Animation / 3D",
    workDescription: "Design Brand Strategy / Animation / 3D",
    textDescription: "Lemonita a refreshing drink brand designed by us to radiate energy and vitality. From its bold identity to its lively persona, we shaped Lemonites to stand out with purpose and style. Even the typography reflects its fresh and vibrant character—crafted to make every sip unforgettable.",
    secondDescription: "Lemonita’s bottle label design speaks volumes. Crafted by Trekuartista, this label combines the energy of lemons with a sleek and modern look. With vibrant colors, elegant textures, and a harmonious typography, the design reflects the authentic taste of Lemonita.",
    thirdDescription: "Inspired by waves of freshness and natural elements, every detail showcases creativity and passion. This design isn’t just visually appealing; it’s a statement of quality and taste in every drop."
  },
  {
    id: 2,
    workImage: gjelber,
    firstSinglePhoto: gjelber2,
    secondSinglePhoto: gjelber3,
    thirdSinglePhoto: gjelber4,
    fourthSinglePhoto: gjelber5,
    fifthSinglePhoto: gjelber6,
    sixthSinglePhoto: gjelber7,
    seventhSinglePhoto: gjelber8,
    eightSinglePhoto: gjelber9,
    ninthSinglePhoto: gjelber10,
    // tenthSinglePhoto: gjelber10,
    eleventhSinglePhoto: gjelber11,
    twelfthSinglePhoto: gjelber12,
    thirteenSinglePhoto: gjelber13,
    fourteenthSinglePhoto: gjelber14,
    fifteenthSinglePhoto: gjelber15,
    sixteenthSinglePhoto: gjelber16,
    seventeenthSinglePhoto: gjelber17,
    eighteenthSinglePhoto: gjelber18,
    nineteenthSinglePhoto: gjelber19,
    twentySinglePhoto: gjelber20,
    twentyOneSinglePhoto: gjelber21,
    twentyTwoSinglePhoto: gjelber22,
    // twentyThreeSinglePhoto: gjelber24,
    // twentyFourSinglePhoto: gjelber23,
    workName: "GJELBËR",
    category: "Brand Strategy",
    workDescription: "Design Brand Strategy / Web Design / Web Development",
    textDescription: "For Gjelber, we created a brand that highlights their commitment to environmental sustainability and habitat preservation. Using earthy tones and organic shapes, we developed a visual identity that reflects the brand's values.",
    secondDescription: "We also designed engaging content to promote Gjelber’s initiatives, focusing on informative social media posts and brochures that inspire community involvement. Our aim was to foster a connection between Gjelber and its audience, raising awareness about environmental issues.",
    thirdDescription: "Additionally, we provided a range of creative assets, including graphics and promotional materials, to support Gjelber’s outreach efforts. These materials are designed to effectively communicate their mission and projects, helping to build a strong community presence and encourage active participation in environmental initiatives."
  },
  {
    id: 10,
    workImage: floil3,
    firstSinglePhoto: floil2,
    secondSinglePhoto: floil3,
    thirdSinglePhoto: floil1,
    fourthSinglePhoto: floil4,
    fifthSinglePhoto: floil5,
    sixthSinglePhoto: floil6,
    seventhSinglePhoto: floil7,
    eightSinglePhoto: floil8,
    ninthSinglePhoto: floil9,
    tenthSinglePhoto: floil10,
    eleventhSinglePhoto: floil11,
    twelfthSinglePhoto: floil12,
    thirteenSinglePhoto: floil13,
    fourteenthSinglePhoto: floil14,
    fifteenthSinglePhoto: floil15,
    workName: "FLOIL - LATEST CAMPAIGN",
    category: "Campaign / Advertising / Shija e gatimeve të nënës",
    workDescription: "Campaign / Advertising / Shija e gatimeve të nënës",
    textDescription: "From a spark of inspiration to bringing characters to life we brought Floil with a unique twist! For us, every detail has a story, and each character delivers Floil’s special taste right to your table. The illustration process involved brainstorming sessions where ideas flowed freely. Our commitment to craftsmanship is evident in the vibrant colors and intricate designs that showcase our artistic style, highlighting our dedication to creativity and quality.",
    // secondDescription: "We also carefully selected a typography that reflects the brand's adventurous spirit and commitment to exceptional service. This thoughtful approach to typography not only reinforces AS Travel’s core values but also ensures a cohesive visual language across all marketing materials.",
    thirdDescription: "We went from the creative stage to the streets! Take a look at our outdoor ad placements, bringing our campaign beyond screens, we’ve taken Floil’s story to billboards, bags, and aprons, making each one a piece of art.",
    hoverText: "Campaign / Advertising / Shija e gatimeve të nënës",
    color: "#1E1E1E",
    buttonTextColor: "white"
  },
  {
    id: 9,
    workImage: astravel1,
    firstSinglePhoto: astravel2,
    secondSinglePhoto: astravel3,
    thirdSinglePhoto: astravel4,
    fourthSinglePhoto: astravel4,
    fifthSinglePhoto: astravel5,
    sixthSinglePhoto: astravel6,
    seventhSinglePhoto: astravel7,
    eightSinglePhoto: astravel8,
    ninthSinglePhoto: astravel9,
    tenthSinglePhoto: astravel10,
    eleventhSinglePhoto: astravel11,
    twelfthSinglePhoto: astravel12,
    thirteenSinglePhoto: astravel13,
    fourteenthSinglePhoto: astravel14,
    fifteenthSinglePhoto: astravel15,
    sixteenthSinglePhoto: astravel16,
    seventeenthSinglePhoto: astravel17,
    eighteenthSinglePhoto: astravel18,
    nineteenthSinglePhoto: astravel19,
    twentySinglePhoto: astravel20,
    twentyOneSinglePhoto: astravel21,
    workName: "AS TRAVEL - 15",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D",
    textDescription: "For AS Travel's 15th anniversary, we had the privilege of designing a new logo that captures their growth and commitment to exceptional travel experiences. We also created a comprehensive social media campaign to celebrate this milestone, featuring engaging content that highlights AS Travel’s achievements, and special promotions. The Social Media Management from us aims helped to connect them with their  audience and foster a sense of community among their loyal customers and potential one.",
    secondDescription: "We also carefully selected a typography that reflects the brand's adventurous spirit and commitment to exceptional service. This thoughtful approach to typography not only reinforces AS Travel’s core values but also ensures a cohesive visual language across all marketing materials.",
    thirdDescription: "By integrating this typography into their logo and promotional content, we aimed to create a strong brand presence that captures the essence of exploration and the excitement of travel, making every interaction memorable for customers.",
    hoverText: "Campaign / Design / Branding",
    color:"#1E1E1E",
    buttonTextColor: "white"
  },
  {
    id: 8,
    workImage: epoque1,
    firstSinglePhoto: epoque2,
    secondSinglePhoto: epoque3,
    thirdSinglePhoto: epoque4,
    fourthSinglePhoto: epoque5,
    fifthSinglePhoto: epoque6,
    sixthSinglePhoto: epoque7,
    seventhSinglePhoto: epoque8,
    eightSinglePhoto: epoque9,
    ninthSinglePhoto: epoque10,
    tenthSinglePhoto: epoque11,
    eleventhSinglePhoto: epoque12,
    twelfthSinglePhoto: epoque13,
    workName: "EPOQUE",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D / Web Design & Development",
    textDescription: "For Époque, we carefullly crafted a logo that captures the timeless elegance of a bygone era, blending intricate vintage details with a modern twist. The fusion of our custom typography brings this vision to life, creating a brand that is both sophisticated and unforgettable.",
    secondDescription: "Our work approach highlights the unique character of Époque, with a refined aesthetic that complements its innovative menu. Every element, was chosen to enhance the dining experience, blending modern touches with timeless appeal.",
    thirdDescription: "The Époque's website that we realized, is a digital showcase that perfectly captures the elegance of dining. From the sleek design to the seamless user experience, every element of the website reflects the timeless charm that they offers.",
    hoverText: "Campaign / Design / Logo / Website Design / Development"
  },

  {
    id: 7,
    workImage: kidsday1,
    firstSinglePhoto: kidsday1,
    secondSinglePhoto: kidsday2,
    thirdSinglePhoto: kidsday3,
    fourthSinglePhoto: kidsday4,
    fifthSinglePhoto: kidsday5,
    sixthSinglePhoto: kidsday6,
    seventhSinglePhoto: kidsday7,
    eightSinglePhoto: kidsday8,
    ninthSinglePhoto: kidsday9,
    tenthSinglePhoto: kidsday10,
    eleventhSinglePhoto: kidsday11,
    twelfthSinglePhoto: kidsday12,
    thirteenSinglePhoto: kidsday13,
    fourteenthSinglePhoto: kidsday14,
    fifteenthSinglePhoto: kidsday15,
    sixteenthSinglePhoto: kidsday16,
    seventeenthSinglePhoto: kidsday17,
    eighteenthSinglePhoto: kidsday18,
    workName: "KIDSDAY",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Animation & 3D",
    textDescription: `We had the privilege to re-brand the identity for Kidsday, creating a logo that beautifully captures the bonds between them. The new design, featuring oval shapes forming the letter "K" and a star, symbolizes the connections and bright future at the heart of Kidsday's mission.`,
    secondDescription: "Blue offers versatility and elegance. This palette encourages creativity and imagination, making it ideal for spaces and brands focused on nurturing young minds. Incorporating various shades of blue can enhance focus and promote a sense of security, making it a perfect choice for any project aimed at children and families.",
    thirdDescription: "For Kidsday, we crafted a playful and engaging visual identity that reflects the app's mission to make every child’s day an adventure. Our design approach incorporated bright, fun icons like butterflies, rabbits, and flowers, bringing the playful and joyful spirit of childhood to life.",
    hoverText: "Campaign / Design / Logo"
  },
  {
    id: 11,
    workImage: kok1,
    firstSinglePhoto: kok1,
    workName: "Kosovo Olympic Committee",
    category: "TV ADVERTISING",
    workDescription: "TV ADVERTISING",
    textDescription: `We poured creativity and dedication into this project for the Kosovo Olympic Committee, contributing to their efforts in representing the nation on the global stage. The project was designed to capture the spirit of athleticism and national pride. Through stunning visuals and powerful storytelling, we aim to inspire and empower our community to reach new heights. This work highlights our ability to contribute to meaningful and high-profile initiatives.`,
    hoverText: "TV ADVERTISING"
  },
  {
    id: 6,
    workImage: work1,
    firstSinglePhoto: vegza1,
    secondSinglePhoto: vegza2,
    thirdSinglePhoto: vegza3,
    fourthSinglePhoto: vegza4,
    fifthSinglePhoto: vegza5,
    sixthSinglePhoto: vegza6,
    seventhSinglePhoto: vegza7,
    eightSinglePhoto: vegza8,
    ninthSinglePhoto: vegza9,
    tenthSinglePhoto: vegza10,
    eleventhSinglePhoto: vegza11,
    workName: "VEGZA",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Marketing Strategy / PR / Animation / Web Design & Development",
    textDescription: "Embracing the fusion of asymmetry and modernism, Vegza's newest logo redesign beautifully intertwines the timeless essence of heritage with the contemporary edge of innovation. With its dynamic asymmetrical lines, each stroke tells a unique story, reflecting the complexity and depth of their architectural vision.",
    secondDescription: "In crafting the social media strategy, we’ve ensured that every post reflects Vegza’s unique perspective. This unveiling of the brand development showcases Vegza’s vision, weaving artistic lines and patterns that always begin with a single thread.",
    thirdDescription: "We redefined Vegza’s brand, merging modern architectural elegance with innovative visual elements. The rebranding showcases Vegza’s commitment to sustainable and functional design, setting them apart in the architectural industry.",
    hoverText: "Campaign / Design / Logo"
  },
  {
    id: 5,
    workImage: mainBoyut,
    firstSinglePhoto: boyut2,
    secondSinglePhoto: boyut3,
    thirdSinglePhoto: boyut16,
    fourthSinglePhoto: boyut4,
    fifthSinglePhoto: boyut5,
    sixthSinglePhoto: boyut6,
    seventhSinglePhoto: boyut7,
    eightSinglePhoto: boyut8,
    ninthSinglePhoto: boyut9,
    tenthSinglePhoto: boyut10,
    eleventhSinglePhoto: boyut11,
    twelfthSinglePhoto: boyut13,
    thirteenSinglePhoto: boyut15,
    fourteenthSinglePhoto: boyut14,
    fifteenthSinglePhoto: boyut17,
    sixteenthSinglePhoto: boyut18,
    workName: "BOYUT",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D",
    textDescription: "We are incredibly proud to have played a key role in Boyut Plastik's transformative journey. Our creative approach to the new logo design blends the brand's unique features with an artistic flair, preserving its core identity while embracing innovation. Boyut Plastik continues to lead in plastics and sustainable solutions, dedicated to ongoing improvement and excellence.",
    secondDescription: "We designed Boyut Plastik’s logo to balance its heritage with innovation. Clean lines and a bold palette highlight its strength and commitment to sustainability, creating a modern identity that reflects the brand's vision.",
    thirdDescription: "Our design for Boyut Plastik focuses on consistency and impact, with every element crafted to showcase the brand's commitment to progress and sustainability. From the logo to packaging designs, every detail was crafted to ensure consistency and impact, allowing the brand to shine in a competitive market.",
    hoverText: "Campaign / Design / Logo",
    color: "#FFFFFF",
    buttonTextColor: "#1E1E1E"
  },
  {
    id: 4,
    workImage: mokne1,
    firstSinglePhoto: mokne2,
    secondSinglePhoto: mokneVideo,
    thirdSinglePhoto: mokne3,
    fourthSinglePhoto: mokne4,
    fifthSinglePhoto: mokne5,
    sixthSinglePhoto: mokne6,
    seventhSinglePhoto: mokne7,
    eightSinglePhoto: mokne8,
    ninthSinglePhoto: mokne9,
    tenthSinglePhoto: mokne10,
    eleventhSinglePhoto: mokne11,
    twelfthSinglePhoto: mokne12,
    thirteenSinglePhoto: mokne13,
    fourteenthSinglePhoto: mokne14,
    workName: "MOKNE",
    category: "Brand Strategy",
    workDescription: "Bottle / Mokne / Logo / Design Brand Strategy / Natyral Water from Istog",
    textDescription: "We are pleased to present our remarkable work in designing the Mokne water bottle label. With innovative thinking, we've injected a new dimension into the market by incorporating the essence of the water's origin into every aspect of its design. This approach not only enhances the aesthetic appeal but also adds a unique storytelling element that resonates with consumers on a deeper level.",
    secondDescription: "MOKNE, a prominent water brand, enlisted our expertise to develop a brand identity that reflects purity, freshness, and natural. By drawing inspiration from the majestic Mokne mountains, we've designed the official logo for the latest water product in the market.",
    thirdDescription: "The logo embodies clarity and precision, symbolizing the water's purity and exclusivity. Starting with our expertise in design, we embarked on a meticulous journey to craft the bottle from its conceptual stage, meticulously modeling every detail, adding texture, shaping its form, and mastering lighting to bring it to life with stunning realism."
  },
  {
    id: 3,
    workImage: work2,
    firstSinglePhoto: termokosBanner,
    secondSinglePhoto: termokos2,
    thirdSinglePhoto: termokos4,
    fourthSinglePhoto: termokos3,
    fifthSinglePhoto: termokos5,
    sixthSinglePhoto: termokos6,
    seventhSinglePhoto: termokos7,
    eightSinglePhoto: termokos8,
    ninthSinglePhoto: termokos9,
    tenthSinglePhoto: termokos10,
    eleventhSinglePhoto: termokos11,
    twelfthSinglePhoto: termokos12,
    thirteenSinglePhoto: termokos13,
    fourteenthSinglePhoto: termokos14,
    fifteenthSinglePhoto: termokos15,
    sixteenthSinglePhoto: termokos16,
    seventeenthSinglePhoto: termokos17,
    eighteenthSinglePhoto: termokos18,
    workName: "Termokos",
    category: "Brand Strategy",
    workDescription: "Logo / Branding / Packaging Design / Marketing Strategy",
    textDescription: "Introducing Termokos’s refreshed identity, symbolizing their commitment to innovation and evolution. We take great pride in partaking in one of the most monumental transformations within the public sphere, marking it as a beacon of success and innovation since the year 2006. ",
    secondDescription: "In the mission of reshaping TERMOKOS, we ingeniously infused the distinctive silhouette of its buildings into the design, preserving the essence of its origin while infusing an artistic approach.",
    thirdDescription: "This new logo embodies the core values functionality, setting the stage for exciting journeys ahead."
  },
  {
    id: 101,
    workImage: dokutech1,
    firstSinglePhoto: dokutech1,
    secondSinglePhoto: dokutech11,
    thirdSinglePhoto: dokutech12,
    fourthSinglePhoto: dokutech14,
    fifthSinglePhoto: dokutech13,
    sixthSinglePhoto: dokutech10,
    seventhSinglePhoto: dokutech2,
    eightSinglePhoto: dokutech3,
    ninthSinglePhoto: dokutech4,
    tenthSinglePhoto: dokutech5,
    eleventhSinglePhoto: dokutech6,
    twelfthSinglePhoto: dokutech7,
    thirteenSinglePhoto: dokutech8,
    fourteenthSinglePhoto: dokutech9,
    fifteenthSinglePhoto: dokutech15,
    sixteenthSinglePhoto: dokutech16,
    seventeenthSinglePhoto: dokutech17,
    eighteenthSinglePhoto: dokutech18,
    workName: "DOKUTECH",
    category: "Brand Strategy",
    workDescription: "Design Brand Strategy / Web Design / Web Development",
    textDescription: "We created the visual identity, communication strategy, and overall design for the jubilee edition of Dokutech.",
    secondDescription: "We encapsulated a decade of tech goodness within one edition's visuals, full of colors and futuristic vibes.",
    thirdDescription: "The main visual in the design is the clock/spinning wheel/wormhole imagery (Wormholes are tunnels in space-time that can transport you to a different time). Its design is inspired by Ernest Borel’s kaleidoscopic watch. All three of them are circles and naturally move forward. And then there’s a silhouette set in the middle of it all. Humans, in time."
  },
  // {
  //   id: 13,
  //   workImage: floilBlejMeZemer,
  //   firstSinglePhoto: floilBlejMeZemer2,
  //   secondSinglePhoto: drops3,
  //   thirdSinglePhoto: drops4,
  //   fourthSinglePhoto: drops5,
  //   fifthSinglePhoto: drops6,
  //   sixthSinglePhoto: drops15,
  //   seventhSinglePhoto: drops8,
  //   eightSinglePhoto: drops9,
  //   ninthSinglePhoto: drops10,
  //   tenthSinglePhoto: drops11,
  //   eleventhSinglePhoto: drops12,
  //   twelfthSinglePhoto: drops13,
  //   thirteenSinglePhoto: drops14,
  //   fourteenthSinglePhoto: drops7,
  //   workName: "FLOIL - BLEJ ME ZEMËR",
  //   category: "Brand Strategy",
  //   workDescription: "Design Brand Strategy / Web Design / Web Development",
  //   textDescription: "For Drops, a modern pub in the heart of Graz, Austria. we crafted a distinctive and captivating identity that reflects the relaxed and welcoming atmosphere of the venue. From logo design to brand development, we ensured that every visual element conveyed the dynamic energy and unique vibe that Drops offers.",
  //   secondDescription: "Our goal was to create a brand presence for Drops that is both vibrant and memorable. The visual identity highlights the  dynamic and social atmosphere that defines Drops.",
  //   thirdDescription: "These carefully crafted symbols help bring the brand to life, encapsulating the essence of Drops in a visual language that speaks to its modern and lively spirit. Every piece of branding, from posters to coasters, is a testament to the harmonious balance between style, fun, and functionality."
  // },
  {
    id: 12,
    workImage: drops1,
    firstSinglePhoto: drops2,
    secondSinglePhoto: drops3,
    thirdSinglePhoto: drops4,
    fourthSinglePhoto: drops5,
    fifthSinglePhoto: drops6,
    sixthSinglePhoto: drops15,
    seventhSinglePhoto: drops8,
    eightSinglePhoto: drops9,
    ninthSinglePhoto: drops10,
    tenthSinglePhoto: drops11,
    eleventhSinglePhoto: drops12,
    twelfthSinglePhoto: drops13,
    thirteenSinglePhoto: drops14,
    fourteenthSinglePhoto: drops7,
    workName: "DROPS",
    category: "Brand Strategy",
    workDescription: "Design Brand Strategy / Web Design / Web Development",
    textDescription: "For Drops, a modern pub in the heart of Graz, Austria. we crafted a distinctive and captivating identity that reflects the relaxed and welcoming atmosphere of the venue. From logo design to brand development, we ensured that every visual element conveyed the dynamic energy and unique vibe that Drops offers.",
    secondDescription: "Our goal was to create a brand presence for Drops that is both vibrant and memorable. The visual identity highlights the  dynamic and social atmosphere that defines Drops.",
    thirdDescription: "These carefully crafted symbols help bring the brand to life, encapsulating the essence of Drops in a visual language that speaks to its modern and lively spirit. Every piece of branding, from posters to coasters, is a testament to the harmonious balance between style, fun, and functionality."
  },
  
  {
    id: 14,
    workImage: herzpraxis1,
    firstSinglePhoto: herxpraxis2,
    secondSinglePhoto: herzpraxis3,
    thirdSinglePhoto: herzpraxis4,
    fourthSinglePhoto: herzpraxis5,
    fifthSinglePhoto: herzpraxis6,
    sixthSinglePhoto: herzpraxis7,
    seventhSinglePhoto: herzpraxis8,
    eightSinglePhoto: herzpraxis9,
    ninthSinglePhoto: herzpraxis10,
    tenthSinglePhoto: herzpraxis11,
    eleventhSinglePhoto: herzpraxis12,
    twelfthSinglePhoto: herzpraxis13,
    thirteenSinglePhoto: herzpraxis14,
    fourteenthSinglePhoto: herzpraxis15,
    fifteenthSinglePhoto: herzpraxis16,
    sixteenthSinglePhoto: herzpraxis17,
    seventeenthSinglePhoto: herzpraxis18,
    eighteenthSinglePhoto: herzpraxis19,
    workName: "HERZPRAXIS",
    category: "Brand Strategy / Web Design ",
    workDescription: "Design Brand Strategy / Web Design / Web Development",
    textDescription: "In this unique project, we created a visual identity that precisely reflects the spirit of a medical practice dedicated to heart health. A visual identity inspired by the heart and the precision of surgery. This simple, meaningful symbol reflects care, expertise, and trust, connecting the clinic’s mission with its patients. From sketch to final design, it communicates clarity and emotional connection.",
    secondDescription: "The logo for Herzpraxis blends simplicity with depth. It takes the universal symbol of the heart and combines it with a clean, modern design that conveys both care and professionalism. The minimalist approach ensures it's instantly recognizable, while the subtle incorporation of surgical precision highlights the clinic's expertise and commitment to excellence.",
    thirdDescription: "The Herzpraxi’s website is designed with the same attention to detail as the brand’s visual identity. It offers a clean, intuitive user experience that aligns with the clinic’s values of care and professionalism. The design, ensures patients can easily access important information while feeling reassured by the clinic's expert approach."
  },
  {
    id: 1,
    workImage: emona,
    firstSinglePhoto: emona2,
    secondSinglePhoto: emona3,
    thirdSinglePhoto: emona4,
    fourthSinglePhoto: emona5,
    fifthSinglePhoto: emona6,
    sixthSinglePhoto: emona7,
    seventhSinglePhoto: emona8,
    eightSinglePhoto: emona9,
    ninthSinglePhoto: emona10,
    workName: "EMONA BRAND",
    category: "Brand Strategy",
    workDescription: "Bottle / Logo / Design Brand Strategy"
  },


]

export { ourWorks }